import React from 'react';
import { useSelector } from 'react-redux'

import { Col, Row, ListGroup, Button, OverlayTrigger, Tooltip, InputGroup, FormControl } from 'react-bootstrap';
import allStyles from './styles';
import {
    getShowAcquirePlatformComponent,
    getShowRegisterCustomersModal,
    getShowEditConsumersModal,
} from '../../../../../store/internal'

import InfiniteScroll from 'react-infinite-scroll-component';
import ModalRegisterConsumersController from '../../../components/modalRegisterConsumers/indexController';
import ModalEditConsumersController from '../../../components/modalEditConsumers/indexController';

import Utils from '../../../../../core/shared/utils';
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import { Channels } from '../../../../../core/enums/channels';
import ButtonSearchCleanInput from '../../../components/buttonSearchCleanInputComponent';

import ChannelLabel from '../../../components/channelLabel';
import TagsComponent from '../../../components/tagsComponent';
import MessagesComponentController from '../../../components/messagesComponent/indexController';
import SelectComponentController from '../../../components/selectComponent/indexController';

const ViewService = ({
    values,
    callbackModalCustomers,
    currentConsumersData,
    props,
    t,
    setAux,
    SetCurrentPage,
    setForcedUpdate,
    resetOverlayIdOnScroll,
    filteringTickets,
    SetCurrentFilterSearch,
    currentFilterSearch,
    currentFilterDatas,
    updateListfunction,
    currentHasMoreInformation,
    currentTicket,
    getTicket,
    onlyRead,
    currentRequester_user,
    blockBool,
    setCurrentStatusPage,
    disableEditTicket,
    defaultInputs,
    confirmationDeleteTicket,
    onClickButtonEdit,
    setCurrentTicket,
    consumersOptions,
    setConsumersOptions,
    selectedConsumers,
    setSelectedConsumers,
    multiselectFilterUsers,
    customCreateRequesterButtonClick,
    onSelectRequester,
    currentTags,
    disableSaveButton,
    updateTicket,
    isbot,
    currentLastUpdatedDate,
    tags,
    selectTags
}) => {

    return (
        <>
            {/* <Loading visibility={isLoading}></Loading> */}
            <ModalRegisterConsumersController token={values.token.value} visibility={useSelector(getShowRegisterCustomersModal)} callback={(userIdCustomer) => callbackModalCustomers(userIdCustomer)} />
            <ModalEditConsumersController token={values.token.value} visibility={useSelector(getShowEditConsumersModal)} callback={(userIdCustomer) => callbackModalCustomers(userIdCustomer)} data={currentConsumersData} />
            {/*Filtros*/}
            <Row className="ps-3">
                {/*Coluna listagem de tickets*/}
                <Row className="align-items-center mx-2" style={{ marginBottom: '1vh', width: '80%' }}>
                    <Col>
                        <Row name="listOfTicketsHead">
                            <Col md={1}
                                className=""
                                style={{
                                    textAlign: 'left',
                                    font: 'var(--px15_19_Bold-font)',
                                    letterSpacing: '0px',
                                    color: '#0C2461',
                                    opacity: '1',
                                    width: '120px',
                                    paddingRight: '0',
                                    // backgroundColor: 'red',
                                }}>
                                {t("home_tickets.view_ticket.list_tickets.filter_label")}
                            </Col>
                            {
                                props.filters.channel === undefined
                                    && props.filters.bot === undefined
                                    && props.filters.employee === undefined
                                    && props.filters.status === undefined
                                    && props.filters.date === undefined ?
                                    <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{t("home_tickets.view_ticket.list_tickets.labels.all")}</Col>
                                    :
                                    <>
                                        {
                                            props.filters.channel !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{t("home_tickets.view_ticket.list_tickets.labels.channel")}</Col>
                                        }
                                        {
                                            props.filters.bot !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{t("home_tickets.view_ticket.list_tickets.labels.bot")}</Col>
                                        }
                                        {
                                            props.filters.employee !== undefined &&
                                            <Col md={2} className="me-2 d-flex align-items-center" style={allStyles.labelBlue2}>{t("home_tickets.view_ticket.list_tickets.labels.assigned")}</Col>
                                        }
                                        {
                                            props.filters.status !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{t("home_tickets.view_ticket.list_tickets.labels.status")}</Col>
                                        }
                                        {
                                            props.filters.date !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{t("home_tickets.view_ticket.list_tickets.labels.date")}</Col>
                                        }
                                    </>
                            }
                        </Row>
                    </Col>
                </Row>
                <Col className="me-1 mx-2" md={5} style={{ maxWidth: '24vw' }}>
                    <Row>
                        <ButtonSearchCleanInput inputPlaceholder={t("home_tickets.button_search_placeholder")} filteringTickets={(auxObj, action) => filteringTickets(auxObj, action)} setCurrentFilterSearch={SetCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col md={2}>
                            <Button style={{ backgroundColor: 'transparent', border: 'none', width: '100%' }} onClick={() => {
                                setAux([]);
                                SetCurrentPage(1);
                                setForcedUpdate(true);
                            }}> <svg id="Grupo_12899" data-name="Grupo 12899" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Caminho_10314" data-name="Caminho 10314" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Caminho_10315" data-name="Caminho 10315" d="M20,11A8.1,8.1,0,0,0,4.5,9M4,5V9H8" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Caminho_10316" data-name="Caminho 10316" d="M4,13a8.1,8.1,0,0,0,15.5,2m.5,4V15H16" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg></Button>
                        </Col>
                    </Row>
                    {/*listagem de tickets*/}
                    <Row className="">
                        <div id="listOfTickets" style={{ overflowY: 'auto', height: '67vh' }} onScroll={() => resetOverlayIdOnScroll()} >
                            <InfiniteScroll
                                dataLength={currentFilterDatas ? currentFilterDatas.length : 10}
                                next={updateListfunction}
                                hasMore={currentHasMoreInformation}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfTickets"}
                            >
                                {

                                    currentFilterDatas ?
                                        <ListGroup variant="flush" >
                                            {currentFilterDatas.map((item, index: number) => {

                                                /* let statusType = Utils.getTicketStatusName(item.status_id);
                                                let statusName = statusType == TicketStatus.NotAssigned
                                                    ? "N/A"
                                                    : statusType.charAt(0).toUpperCase(); */

                                                return (
                                                    <ListGroup.Item className="" id={"itemTicketList" + (index + 1)}
                                                        onClick={() => {
                                                            getTicket({ id: item.id }, {});
                                                        }}
                                                        style={{ cursor: 'pointer', minHeight: '4rem', backgroundColor: currentTicket && currentTicket.id && currentTicket.id === item.id && '#f0f8ff' }} key={index + 1}
                                                    >
                                                        {/*item ticket*/}
                                                        <Row style={{ color: '#0C2461' }} className="d-flex align-items-center">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        #{item.ticket_reference_id}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Col md={2}
                                                                    className="d-flex align-items-center"
                                                                    style={{
                                                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                        borderRadius: '4px',
                                                                        opacity: '1',
                                                                        textAlign: 'left',
                                                                        font: 'var( --px15_19_Bold-font)',
                                                                        letterSpacing: '0px',
                                                                        height: '2.2vh',
                                                                    }}>
                                                                    <p style={{ padding: '0', margin: '0vw 0vw 0vw -0.2vw' }}>#{item.ticket_reference_id}</p>
                                                                </Col>
                                                            </OverlayTrigger>
                                                            <Col md={7} style={{ width: '62%', textAlign: 'left', font: 'var( --px15_19_Bold-font)', letterSpacing: '0px', color: '#707070', opacity: '1', fontWeight: item.bold ? 'bolder' : '' }}>
                                                                {item.requester_user != null ?
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            // trigger={['hover', 'focus']}
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {(item.requester_user.name != null) ?
                                                                                        <>{item.requester_user.name} {item.requester_user.lastname ? (" " + item.requester_user.lastname) : ""}</>
                                                                                        : null
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        // show={overlayId === "requester_" + item.id ? true : false}
                                                                        // onToggle={(toggle) => { handleOverlayTrigger("requester_" + item.id, toggle) }}
                                                                        >
                                                                            <span style={{
                                                                                height: '35%',
                                                                            }}>
                                                                                {item.requester_user.name} {item.requester_user.lastname ? (" " + item.requester_user.lastname) : ""}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        N/A
                                                                    </>
                                                                }
                                                            </Col>
                                                            <Col sm={2} md={2} className="" style={{ width: '8%' }}>
                                                                {item.status_id === TicketStatus.Resolved ?
                                                                    <Button style={{ backgroundColor: '#707070', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {t("ticket_status.resolved")}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className='FilterTicketsRow '>{t("ticket_status_symbol.resolved")}</span>
                                                                        </OverlayTrigger>
                                                                    </Button>
                                                                    :
                                                                    // statusName === "P" ?
                                                                    item.status_id === TicketStatus.Pending ?
                                                                        <Button style={{ backgroundColor: '#DE2450', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {t("ticket_status.pending")}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span className='FilterTicketsRow '>{t("ticket_status_symbol.pending")}</span>
                                                                            </OverlayTrigger>
                                                                        </Button>
                                                                        :
                                                                        // statusName === "E" ?
                                                                        item.status_id === TicketStatus.OnHold ?
                                                                            <Button style={{ backgroundColor: '#FBBD4E', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            {t("ticket_status.on_hold")}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow '>{t("ticket_status_symbol.on_hold")}</span>
                                                                                </OverlayTrigger>
                                                                            </Button>
                                                                            :
                                                                            // statusName === "A" ?
                                                                            item.status_id === TicketStatus.Open ?
                                                                                <Button style={{ backgroundColor: '#4A69BD', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id='tooltip'>
                                                                                                {t("ticket_status.open")}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className='FilterTicketsRow '>{t("ticket_status_symbol.open")}</span>
                                                                                    </OverlayTrigger>
                                                                                </Button>
                                                                                :
                                                                                // statusName === "N" ?
                                                                                item.status_id === TicketStatus.New ?
                                                                                    <Button style={{ backgroundColor: '#17a589', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id='tooltip'>
                                                                                                    {t("ticket_status.new")}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className='FilterTicketsRow '>{t("ticket_status_symbol.new")}</span>
                                                                                        </OverlayTrigger>
                                                                                    </Button>
                                                                                    :
                                                                                    // statusName === "B" ??
                                                                                    item.status_id === TicketStatus.Abandoned ?
                                                                                        <Button style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                            <OverlayTrigger
                                                                                                key='top'
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip id='tooltip'>
                                                                                                        {t("ticket_status.abandoned")}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <span className='FilterTicketsRow '>{t("ticket_status_symbol.abandoned")}</span>
                                                                                            </OverlayTrigger>
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                }
                                                            </Col>
                                                            <Col md={1} className='align-self-center'>
                                                                {item.who_is_viewing &&
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {
                                                                                    t("home_tickets.list_tickets.body.who_is_viewing", { who_is_viewing: item.who_is_viewing })
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-1"
                                                            style={{
                                                                textAlign: 'left',
                                                                font: 'var(--px15_19-font-Roboto)',
                                                                letterSpacing: '0px',
                                                                color: '#707070',
                                                                opacity: '1',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            <div className='oneLineClampEllipsis nopadding'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    // trigger={['hover', 'focus', 'click']}
                                                                    overlay={
                                                                        <Tooltip id={'tooltip*ID*' + item.id}>
                                                                            {item.last_message}
                                                                        </Tooltip>
                                                                    }
                                                                // show={overlayId === "last_message_" + item.id ? true : false}
                                                                // onToggle={(toggle) => { handleOverlayTrigger("last_message_" + item.id, toggle) }}
                                                                >
                                                                    <span>
                                                                        {item.last_message}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                        :
                                        <div className='' style={{ overflow: 'hidden' }}>
                                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '5vh' }}>
                                                <Col md={3} className="d-flex justify-content-center">
                                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="3vw" viewBox="0 0 24 24">
                                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </Col>
                                            </Row>
                                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%' }}>
                                                <Col md={7}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                                        {
                                                            t("home_tickets.list_tickets.body.empty_list")
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                }
                            </InfiniteScroll>
                        </div>
                    </Row>
                </Col>

                <Col md className="me-3">
                    {/*CHAT*/}
                    <MessagesComponentController setPhoneNumber={props.setPhoneNumber} setOpenModalPhone={props.setOpenModalPhone} onlyRead={onlyRead} isAllowedMessage={blockBool} currentRequester_user={currentRequester_user} goBackToTicketList={false} name={values.user.name + (values.user.lastname ? (" " + values.user.lastname) : "")} onPostTicket={async () => { }} showSubjectError={null} agent={values.user} currentTicket={currentTicket} token={values.token.value}
                        updateList={() => {
                            setAux([]);
                            SetCurrentPage(1);

                            setCurrentStatusPage("Updating: " + Date.now().toString());

                            getTicket({ id: currentTicket.id }, {}).finally(() => {
                                defaultInputs();
                            });
                        }}
                        hiddenChatEditor={
                            currentTicket && (currentTicket.status_id === TicketStatus.Resolved || currentTicket.status_id === TicketStatus.Abandoned || currentTicket.deleted_at !== null) ?
                                true : false
                        }
                        showHistoryButton
                        showOptionsButton={
                            /* props.currentViewFilter && (props.currentViewFilter === "deleted" || props.currentViewFilter === "finalized")?
                                false : true */
                            !disableEditTicket
                        }
                        deleteTicket={() => confirmationDeleteTicket()} ticketChannel={currentTicket ? currentTicket.channel_id : Channels.NotAssigned}
                        getTicket={getTicket}
                        setTicketInfo={setCurrentTicket}
                        editTicket={!disableEditTicket}
                        renderMessagesChat={false}
                    >
                    </MessagesComponentController>
                </Col>

                {/*coluna dropdowns*/}
                <Col sm={3} md={3}
                    style={{ maxWidth: '22vw', overflowY: 'auto', overflowX: 'hidden', height: useSelector(getShowAcquirePlatformComponent).visibility ? '70vh' : '76vh' }}>
                    {/*DROPDOWNS*/}
                    <Row
                        className="mb-0 pb-1"
                        style={{
                            background: '#F9F9F9 0% 0% no-repeat padding-box',
                            borderRadius: '3px',
                            opacity: '1',
                        }}
                    >
                        <Col className="mt-2" style={{ font: 'var(--px15_19-font-Roboto)' }}>
                            {/*Solicitante dropdown*/}
                            <Row className="mt-2" style={allStyles.dropdownOptionRow}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col className="align-self-center">
                                        {t("home_tickets.home_create_ticket.requester_field_label")}
                                    </Col>
                                    <Col md={7} className="d-flex justify-content-end" style={{ height: '70%' }}>
                                        <Button onClick={() => onClickButtonEdit()} id={"editConsumerButtonId"} style={{ display: 'none' }} disabled={disableEditTicket} className="buttonAsText">{t("home_tickets.home_create_ticket.edit_button")}</Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <SelectComponentController
                                            options={consumersOptions}
                                            setOptions={setConsumersOptions}
                                            selecteds={selectedConsumers}
                                            setSelecteds={setSelectedConsumers}
                                            singleSelect={true}
                                            isFormNoFilter={true}
                                            title={t("home_tickets.home_create_ticket.select_component.requester.field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "clearSearch": t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "noOptions": t("home_tickets.home_create_ticket.select_component.no_options", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "search": t("home_tickets.home_create_ticket.select_component.search", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "selectAll": t("home_tickets.home_create_ticket.select_component.select_all", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "selectAllFiltered": t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: t("home_tickets.home_create_ticket.requesters_field_label") }),
                                                    "selectSomeItems": t("home_tickets.home_create_ticket.requester_field_label"),
                                                    "create": t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={true}
                                            filterOptions={multiselectFilterUsers}
                                            hasNullOption={false}
                                            createItemLabel={t("home_tickets.home_create_ticket.select_component.create_consumer")}
                                            createItemOnClick={customCreateRequesterButtonClick}
                                            onSelect={(value) => onSelectRequester(value)}
                                            disabled={disableEditTicket}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        Chatbot
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <InputGroup>
                                            <FormControl className="form-control-Default"
                                                placeholder="Informe seu e-mail  "
                                                aria-label="Informe seu e-mail"
                                                aria-describedby="Informe seu e-mail"
                                                size='lg'
                                                type='email'
                                                id="user_email"
                                                name="email"
                                                autoComplete='email'
                                                disabled={true}
                                                value={currentTicket?.bot_cxpress_name ? currentTicket?.bot_cxpress_name : "Sem Chatbot"}
                                            >
                                            </FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        {t("home_tickets.home_create_ticket.tags_field_label")}
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <TagsComponent
                                            tags={tags} currentTags={currentTags} onSelect={() => { props.setFilledEditTicket && props.setFilledEditTicket(true); }} setCurrentTags={selectTags}></TagsComponent>
                                    </Col>
                                </Row>
                            </Row>
                            {
                                // props.currentViewFilter && (props.currentViewFilter === "deleted" || props.currentViewFilter === "finalized") || (blockBool == true) || (onlyRead == true) ?
                                <Row style={{ marginBottom: '1vh' }} className="mt-4">
                                    <Row style={{ marginLeft: '0vw' }}>
                                        <Col className="d-flex justify-content-center">
                                            <Button disabled={disableSaveButton} type='submit' onClick={updateTicket} className="buttonBlue">{t("home_tickets.home_create_ticket.save_ticket")}</Button>
                                        </Col>
                                    </Row>
                                </Row>
                            }
                        </Col>
                    </Row>

                    <Row
                        className="d-flex justify-content-center mt-2"
                        style={{
                            background: '#F9F9F9 0% 0% no-repeat padding-box',
                            borderRadius: '3px',
                            opacity: '1',
                            //height: '23.14vh',
                        }}
                    >
                        <Row className="d-flex justify-content-center mt-2">
                            <Col className="mx-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {t("home_tickets.list_tickets.headers.id")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    #{currentTicket != null &&
                                        currentTicket.ticket_reference_id
                                    }
                                </div>
                            </Col>

                            {/*CARD BOT*/}
                            <Col className="mx-2"

                                style={{

                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {t("home_tickets.home_create_ticket.bot_field_label")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {isbot()}
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2">
                            {/*CARD canal*/}
                            <Col className="mx-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {t("home_tickets.home_create_ticket.channel_field_label")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {/* {currentTicket && Utils.getTicketChannelName(currentTicket.channel_id)} */}
                                    {currentTicket && ChannelLabel(t(Utils.getTicketChannelTranslationName(currentTicket.channel_id)), currentTicket.channel_id, { color: "rgb(30, 30, 30)" })}
                                </div>
                            </Col>

                            {/*CARD data*/}
                            <Col className="mx-2 mb-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {
                                        t("home_tickets.home_create_ticket.date")
                                    }
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {Utils.formatedDate(currentLastUpdatedDate)}
                                </div>
                            </Col>
                        </Row>

                        {/* <Row className="d-flex justify-content-center my-2">
                            <Col className="mx-2"
                                style={{
                                    height: '7vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-1 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    Assunto
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {<OverlayTrigger
                                        key='top'
                                        placement='left'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {(currentTicket != null) && (currentTicket.subject)}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            {(currentTicket != null) && ((currentTicket.subject).length <= Math.ceil(window.innerWidth / 70)) ? currentTicket.subject : (currentTicket != null) ? (currentTicket.subject).substr(0, Math.ceil(window.innerWidth / 40)) + "..." : null}
                                        </span>
                                    </OverlayTrigger>}
                                </div>
                            </Col>
                        </Row> */}
                    </Row>
                </Col>
            </Row >
        </>
    );
}

export default ViewService;