import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import { getToken } from '../../../../store/token'
import { getIdCompany } from '../../../../store/company'
import { getIdEmployee } from '../../../../store/employee'
import { getIdUser, getNameUser, getUserLastName, getProfileId } from '../../../../store/user'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowCreateConsumersModal,
    getShowEditConsumersModal,
    getShowConfirmationModal,
    setShowEditTicketsModal,
    getShowEditTicketsModal,
    setShowEditAgentsModal,
    getSortByList,
    getOrderByList,
    getShowEditAgentsModal,
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import BackendConstants from '../../../../core/constants/backend-constants';
import UserService from '../../../../services/user-service';
import { ListName } from '../../../../core/enums/order-sort-by';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { AgentData, AgentTicketsData } from '../indexModel';
import constsRouters from "../../../../routes/constsRouter";
import ViewAgents from '.';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const ViewAgentsController = () => {

    const emp = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowCreateConsumersModal: useSelector(getShowCreateConsumersModal),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
            getShowEditAgentsModal: useSelector(getShowEditAgentsModal),
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [currentTicketsAgents, setCurrentTicketsAgents] = useState(undefined);
    const [currentAgent, setCurrentAgent] = useState(null);
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentParams, SetCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, SetCurrentPage] = useState(1);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentState, setCurrentState] = useState("");
    const [resetingFilters, setResetingFilters] = useState(false);
    const [yoursTickets, setYoursTickets] = useState(false);
    const [/*currentTotalAllTickets*/, SetCurrentTotalAllTickets] = useState(0);
    const [/*currentTotalYoursTickets*/, SetCurrentTotalYoursTickets] = useState(0);
    const [currentTicket, setCurrentTicket] = useState(null);

    const { t } = useTranslation()

    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        if (values.internal.getShowEditAgentsModal) {
            dispatch(setShowEditAgentsModal(false));
        }
    }, []); 

    useEffect(() => {
        const controller = new AbortController();

        setCurrentAgent((state: { name: string; }) => ({
            ...state,
            name: state ? state.name : emp.state['name'],
        }));

        if (values.internal.getShowEditTicketsModal === false) {
            SetCurrentPage((state) => {
                SetCurrentParams((state2) => {
                    getAllTicketsToPagination(Object.assign(state2, (Object.assign({ user_id: emp.state['user_id'] }, { limit: currentLimit, page: state }))));
                    return state2;
                });
                return state;
            });
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    const getAgent = async (userID: string) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/agent/' + userID, { headers },
            (response: Object) => {
            },
            (data: AgentData) => {
                if (data.status === 200) {
                    setCurrentAgent({
                        address: data.data.employees[0].address,
                        id: data.data.employees[0].id,
                        user_id: userID,
                        profile_id: data.data.employees[0].profile_id,
                        name: data?.data?.employees[0]?.name + " " + (data?.data?.employees[0]?.name && data?.data?.employees[0]?.lastname ? data.data.employees[0].lastname : ""),
                        document_number: data.data.employees[0].document_number,
                        company_id: data.data.employees[0].company_id,
                        company_number: data.data.employees[0].company_number,
                        email: data.data.employees[0].email,
                        facebook: data.data.employees[0].facebook,
                        instagram: data.data.employees[0].instagram,
                        lastname: data.data.employees[0].lastname,
                        observations: data.data.employees[0].observations,
                        phone: data.data.employees[0].phone,
                        twitter: data.data.employees[0].twitter,
                        whatsapp: data.data.employees[0].whatsapp,
                    });
                }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    // const getAllTicketsToPagination = (params: { user_id: string; } & { limit: number; page: number; }) => {
    //     getAllTickets(params, setCurrentTicketsAgents, aux, setAux);
    // }

    const getAllTickets = async (params: { user_id: string; } & { limit: number; page: number; }, setData: Function, aux: any[], setAux: Function) => {
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };
        if (values.internal.getSortByList.listName === ListName.ListOfTickets && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
            params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        }

        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/agent/' + params.user_id + '/tickets', { headers, /*signal,*/ params: params },
            (response: Object) => { },
            (data: AgentTicketsData) => {
                if (data.status === 200) {
                    setValuesOfNumbersTickets(data.data.total, data.data.total_my_tickets);

                    setAux((state: any[]) => {

                        if (!state) {
                            state = [];
                        }

                        let aux = state.concat(data.data.tickets);
                        setData(aux);
                        return aux;
                    });
                } else if (aux.length === 0) {
                    setData(undefined);
                }

            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    }

    const updateListAfterDelete = () => {
        setAux([]);
        SetCurrentPage(1);
        setAux(undefined);
        setCurrentState("deleting");

        if (currentState === "deleting") {
            setCurrentState("");
        }
    }

    const setCurrentTicketFunction = (value: any) => {
        setCurrentTicket({id: value?.id.split("*ID*")[0].split("*who_is_viewing*")[0]});
    }

    const listOfAgentPage = () => {
        if (values.internal.getShowEditTicketsModal === false) {
            navigate(constsRouters.routers.agents.path);
        } else {
            dispatch(setShowEditTicketsModal(false));
            SetCurrentPage(1);
            setAux([]);
            setAux(undefined);
            SetCurrentParams({});

            setCurrentState("returning");
            if (currentState === "returning") {
                setCurrentState("");
            }
        }
    }

    const getAllTicketsToPagination = (params: { user_id: string; } & { limit: number; page: number; }) => {
        getAllTickets(params, setCurrentTicketsAgents, aux, setAux);
    }

    const setValuesOfNumbersTickets = (totalAllTickets: number, totalYoursTickets: number) => {
        SetCurrentTotalAllTickets(totalAllTickets);
        SetCurrentTotalYoursTickets(totalYoursTickets);
    }

    const showEditModal = () => {
        SetCurrentPage(1);
        setAux([]);
        getAgent(emp.state['user_id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
            dispatch(setShowEditAgentsModal(true));
        })
    }

    const filteringTickets = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        SetCurrentPage(1);
        setAux([]);
        setAux(undefined);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        if (action === "clean") {
            SetCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (e.currentTarget.id == "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            setCurrentState("searching");

            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch != null && currentFilterSearch != "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        dispatch(setShowEditTicketsModal(false));

        SetCurrentParams(auxObj);
    }

    const cleanList = () => {
        SetCurrentParams({});
        SetCurrentPage(1);
        setAux([]);
        setAux(undefined);
        setResetingFilters(true);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const showCreateTicket = () => {
        navigate(constsRouters.routers.createTicket.path);
    }

    const callbackOrderByTickets = (column: number, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        setCurrentState(column + order);
        document.getElementById(ListName.ListOfTickets).scrollTop = 0;
    }

    const callbackModalAgents = () => {
        SetCurrentPage(1);
        setAux([]);
        getAgent(emp.state['user_id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
        });
    }

    return <ViewAgents
        t={t}
        isLoading={isLoading}
        values={values}
        callbackModalAgents={callbackModalAgents}
        currentAgent={currentAgent}
        setCurrentTicketFunction={setCurrentTicketFunction}
        updateListfunction={updateListfunction}
        listOfAgentPage={listOfAgentPage}
        filteringTickets={filteringTickets}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        showEditModal={showEditModal}
        currentState={currentState}
        currentTicket={currentTicket}
        currentParams={currentParams}
        getAllTickets={getAllTickets}
        setCurrentState={setCurrentState}
        cleanList={cleanList}
        resetingFilters={resetingFilters}
        setResetingFilters={setResetingFilters}
        yoursTickets={yoursTickets}
        setYoursTickets={setYoursTickets}
        currentTicketsAgents={currentTicketsAgents}
        updateListAfterDelete={updateListAfterDelete}
        showCreateTicket={showCreateTicket}
        callbackOrderByTickets={callbackOrderByTickets}
    />
}

export default ViewAgentsController;