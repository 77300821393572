import { TicketFilterType } from "../../../../core/enums/ticket-filter-type";
import constsRouters from "../../../../routes/constsRouter";
import { useState, useEffect, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom';

import { getToken } from '../../../../store/token'
import { getIdCompany } from '../../../../store/company'
import { getIdEmployee } from '../../../../store/employee'


import { getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../../store/user'

import 'bootstrap/dist/css/bootstrap.min.css';

import { getShowEditTicketsModal, setShowEditTicketsModal, getSortByList, getOrderByList, setShowAlertFeedback } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import BackendConstants from '../../../../core/constants/backend-constants';
import UserService from '../../../../services/user-service';
import { ListName, SortBy } from '../../../../core/enums/order-sort-by';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { TicketsHistoryInterface, TicketHistoricTotalsInterface } from '../../../../services/requestsInterfacesModel';
import { useTranslation } from 'react-i18next';
import HomeHistoryChatbot from ".";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const AppRequesterConst = new AppRequesterController();

const HomeHistoryChatbotController = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [currentTotalFinalized, SetCurrentTotalFinalized] = useState(0);
    const [/*currentTotalDeleted*/, SetCurrentTotalDeleted] = useState(0);
    const [currentTicket, setCurrentTicket] = useState(null);

    const [aux, setAux] = useState([]);
    const [currentState, SetCurrentState] = useState("finalized");
    const [currentViewFilter, setCurrentViewFilter] = useState(null);
    const [resetingFilters, setResetingFilters] = useState(false);
    const [yoursTickets, setYoursTickets] = useState(false);
    const [isDeleted, SetIsDeleted] = useState(false);
    const [currentParams, SetCurrentParams] = useState({});
    const [currentFilterDatas, setcurrentFilterDatas] = useState(undefined);
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);

    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [dataText, setDataText] = useState(t("history_tickets.finalized_date"));
    const [ticketStateLevelType, setTicketStateLevelType] = useState(TicketFilterType.Finalized);

    const [currentPage, SetCurrentPage] = useState(1);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limit);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        if (currentViewFilter !== null) {
            let token = values.token.value;
            if (token) {
                //socket.createSocket(token);
                SetCurrentPage((state) => {
                    SetCurrentParams((state2) => {
                        getAllTicketsToPaginationFinalizedDeleted(Object.assign(state2, { limit: currentLimit, page: state }), currentViewFilter, getAllTicketsFinalizedDeleted, controller);
                        return state2;
                    });
                    return state;
                });
            }
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState, currentViewFilter, currentLimit]);

    useEffect(() => {
        if (location && location.state && location.state['viewFilterState'] === TicketFilterType.Deleted) {
            deletedFiltersClick();
        } else {
            finalizedFiltersClick();
        }

        // url update
        if (location && location.state && location.state['urlViewTicket']) {
            window.history.replaceState({}, document.title, location.state['urlViewTicket']);
            dispatch(setShowAlertFeedback({ visibility: true, message: t("history_tickets.feedback.ticket_not_founded"), signalIcon: false }));
        }
    }, [dispatch, location]);

    const getAllTicketsFinalizedDeleted = async (params: { limit: number; page: number; }, controller: AbortController) => {
        if (!isLoading) {
            // const headers = {
            //     "Content-Type": "application/json; charset=utf-8",
            //     "Authorization": "Bearer " + values.token.value
            // };
            if (values.internal.getSortByList.listName === ListName.ListOfTickets &&
                (values.internal.getSortByList.sort_by !== null &&
                    ((values.internal.getSortByList.sort_by === SortBy.updated_date && ticketStateLevelType === TicketFilterType.Finalized) ||
                        (values.internal.getSortByList.sort_by === SortBy.deleted_date && ticketStateLevelType === TicketFilterType.Deleted))
                ) && values.internal.getOrderByList !== null
            ) {
                params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
            }
    
            const headers = UserService.getHeaders(), signal = controller.signal;
    
            await AppRequesterConst.Get(
                '/ticket/historic?level=1', { headers, signal, params: params },
    
                (response: Object) => { },
                (data: TicketsHistoryInterface) => {
                    if (data.status === 200 && data.data.tickets.length > 0) {
                        setAux((state) => {
                            let aux = state.concat(data.data.tickets);
                            setcurrentFilterDatas(aux);
                            return aux;
                        });
                    }
                    else if (aux.length === 0 /* && data.status !== 204 */) {
                        setcurrentFilterDatas(undefined);
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );
    
            await AppRequesterConst.Get(
                '/ticket/historic/totals?level=1', { headers },
                (response: Object) => { },
                (data: TicketHistoricTotalsInterface) => {
                    if (data.status === 200) {
                        setValuesOfNumbersTickets(data.data.total_resolved_tickets, data.data.total_excluded_tickets)
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            )
        }
    }

    const getAllTicketsToPaginationFinalizedDeleted = (params: { limit: number; page: number; }, currentViewFilter: string | boolean, getAllTicketsFinalizedDeleted: Function, controller: AbortController, viewFilter?: boolean | TicketFilterType) => {
        if (currentViewFilter === TicketFilterType.Finalized) {
            return getAllTicketsFinalizedDeleted(params, controller);
        } else if ((currentViewFilter === TicketFilterType.Deleted || isDeleted) || (viewFilter && viewFilter === TicketFilterType.Deleted)) {
            return getAllTicketsFinalizedDeleted(Object.assign({ deleted: true }, params), controller);
        } else {
            return getAllTicketsFinalizedDeleted(params, controller);
        }
    }

    const setValuesOfNumbersTickets = (totalFinalized: number, totalDeleted: number) => {
        return (
            SetCurrentTotalFinalized(totalFinalized),
            SetCurrentTotalDeleted(totalDeleted)
        )
    }

    const deletedFiltersClick = () => {
        SetCurrentState("deleted");
        setCurrentViewFilter(TicketFilterType.Deleted);

        if (currentState === "deleted") {
            SetCurrentState("");
        }
        dispatch(setShowEditTicketsModal(false));
        SetIsDeleted(true);
        setDataText("Data de exclusão");
        setTicketStateLevelType(TicketFilterType.Deleted);
        SetCurrentParams({});
        if (document.getElementById("SeachInput") as HTMLInputElement !== null)
            (document.getElementById("SeachInput") as HTMLInputElement).value = "";


        if (currentFilterDatas !== undefined) {
            if ((document.getElementById("Allcheckboxes") as HTMLInputElement) != null) {
                (document.getElementById("Allcheckboxes") as HTMLInputElement).checked = false;
            }
            for (let i = 0; i < currentFilterDatas.length; i++) {
                if ((document.getElementById("checkboxes" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxes" + i) as HTMLInputElement).checked = false;
                }
            }
        }

        if (document.getElementById("buttonDeleteAllSelected")) {
            document.getElementById("buttonDeleteAllSelected").style.display = 'none';
        }

        document.getElementById("filterAllTickets").className = "buttonTicketsHome btn btn-primary";
        // document.getElementById("filterYourTickets").className = "buttonTicketsActive btn btn-primary";
        SetCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }
        setResetingFilters(true);
    }

    const finalizedFiltersClick = () => {
        SetCurrentState("finalized");
        setCurrentViewFilter(TicketFilterType.Finalized);

        if (currentState === "finalized") {
            SetCurrentState("");
        }
        dispatch(setShowEditTicketsModal(false));
        setDataText("Data de finalização");
        setTicketStateLevelType(TicketFilterType.Finalized);
        SetIsDeleted(false);
        SetCurrentParams({});

        if (document.getElementById("SeachInput") as HTMLInputElement !== null)
            (document.getElementById("SeachInput") as HTMLInputElement).value = "";

        if (currentFilterDatas !== undefined) {
            if ((document.getElementById("Allcheckboxes") as HTMLInputElement) != null) {
                (document.getElementById("Allcheckboxes") as HTMLInputElement).checked = false;
            }
            for (let i = 0; i < currentFilterDatas.length; i++) {
                if ((document.getElementById("checkboxes" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxes" + i) as HTMLInputElement).checked = false;
                }
            }
        }

        if (document.getElementById("buttonDeleteAllSelected")) {
            document.getElementById("buttonDeleteAllSelected").style.display = 'none';
        }
        document.getElementById("filterAllTickets").className = "buttonTicketsActive btn btn-primary";
        // document.getElementById("filterYourTickets").className = "buttonTicketsHome btn btn-primary";
        SetCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }
        setResetingFilters(true);
    }

    const filteringTickets = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        SetCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        if (action === "clean") {
            SetCurrentFilterSearch("");
            auxObj = {};
            SetCurrentState("searching");
            if (currentState === "searching") {
                SetCurrentState("");
            }
        } else if (e.currentTarget.id === "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            SetCurrentState("searching");

            if (currentState === "searching") {
                SetCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            SetCurrentState("filtering");

            if (currentState === "filtering") {
                SetCurrentState("");
            }
        }

        SetCurrentParams(auxObj);
    }

    const setCurrentTicketFunction = (value: any) => {
        setCurrentTicket(value);
    }

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    const cleanList = () => {
        SetCurrentParams({});
        SetCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        setResetingFilters(true);

        SetCurrentState("filtering");

        if (currentState === "filtering") {
            SetCurrentState("");
        }
    }

    const showCreateTicket = () => {
        navigate(constsRouters.routers.createTicket.path);
    }

    const returnDefaultHistoryPage = () => {
        if (values.internal.getShowEditTicketsModal === false) {
            navigate(constsRouters.routers.history.path);
        } else {
            dispatch(setShowEditTicketsModal(false));
            SetCurrentPage(1);
            setAux([]);
            ///setCurrentTicketsAgents(undefined);
            SetCurrentParams({});

            SetCurrentState("returning");
            if (currentState === "returning") {
                SetCurrentState("");
            }
        }
    }

    const callbackOrderByTickets = (column: any, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        SetCurrentState(column + order);
        document.getElementById(ListName.ListOfTickets).scrollTop = 0;
    }

    return <HomeHistoryChatbot
        isLoading={isLoading}
        setCurrentTicketFunction={setCurrentTicketFunction}
        updateListfunction={updateListfunction}
        t={t}
        finalizedFiltersClick={finalizedFiltersClick}
        currentTotalFinalized={currentTotalFinalized}
        filteringTickets={filteringTickets}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        phoneNumber={phoneNumber}
        openModalPhone={openModalPhone}
        setOpenModalPhone={setOpenModalPhone}
        values={values}
        returnDefaultHistoryPage={returnDefaultHistoryPage}
        setPhoneNumber={setPhoneNumber}
        currentState={currentState}
        SetCurrentState={SetCurrentState}
        currentViewFilter={currentViewFilter}
        currentTicket={currentTicket}
        currentParams={currentParams}
        cleanList={cleanList}
        resetingFilters={resetingFilters}
        setResetingFilters={setResetingFilters}
        yoursTickets={yoursTickets}
        setYoursTickets={setYoursTickets}
        callbackOrderByTickets={callbackOrderByTickets}
        dataText={dataText}
        showCreateTicket={showCreateTicket}
        currentFilterDatas={currentFilterDatas}
        ticketStateLevelType={ticketStateLevelType}
    />
}

export default HomeHistoryChatbotController