import React, { FC, useState, useEffect } from 'react';
import { OrderBy } from '../../../../core/enums/order-sort-by';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderByList, getSortByList, setSortByList } from '../../../../store/internal';
import { ButtonOrderByListInterface } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { ButtonOrderByListController } from './indexController';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const ButtonOrderByList: FC<ButtonOrderByListInterface> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const values: TokenInterface = {
        internal: {
            getOrderByList: useSelector(getOrderByList),
            getSortByList: useSelector(getSortByList),
        }
    };

    const [orderBy, setOrderBy] = useState(
        (values.internal.getSortByList.sort_by === props.columnIdName && values.internal.getSortByList.listName === props.listName) ?
            values.internal.getOrderByList :
            (props.defaultOrder !== undefined) ?
                props.defaultOrder : OrderBy.asc
    );

    const [dataText, setDataText] = useState(localStorage.getItem('filterOptionTicketsCx') || 'home_tickets.list_tickets.headers.order_by_create_date_title');
    const [coumnIdName, setColumnIdName] = useState(props.columnIdName);

    const ButtonOrderByListControllerConst = new ButtonOrderByListController(dispatch, setSortByList, props, orderBy, setOrderBy);
    
    const changeFilter = (value, cIdName) => {
        setColumnIdName(cIdName);
        localStorage.setItem('filterOptionTicketsCx', value);
        setDataText(value);
        ButtonOrderByListControllerConst.orderByFunction(cIdName);
    }

    const popoverOptions = () => (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={() => changeFilter('home_tickets.list_tickets.headers.order_by_create_date_title', 'updated_level2_at') } className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Data de criação</Button>
                <div>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    <Button onClick={() => changeFilter('home_tickets.list_tickets.headers.order_by_update_date_title', 'consumer_last_message') } className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Data de atualização</Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <div>
            {
                props.twoOptions
                ?
                <OverlayTrigger rootClose={true} trigger={['click']} placement="bottom" overlay={popoverOptions()}>
                    <span onClick={() => {}} style={{ cursor: 'pointer' }} className="me-1" >{t(dataText)}</span>
                </OverlayTrigger>
                :
                <span onClick={() => { ButtonOrderByListControllerConst.orderByFunction() }} style={{ cursor: 'pointer' }} className="me-1" >{props.title}</span>
            }
            <span onClick={() => { ButtonOrderByListControllerConst.orderByFunction() }} style={{ cursor: 'pointer' }}>
                {orderBy === OrderBy.desc ?
                    <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg> :
                    <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                }
            </span>
        </div>
    );
}


export default ButtonOrderByList;