import { FC, useContext, useEffect, useState } from "react";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { getShowConfirmationModal, getShowEditTicketsModal, getShowMeetButton, getShowSendTicketCopyModal, setBlockLoadTicketBackup, setShowAlertFeedback, setShowConfirmationModal, setShowEditTicketsModal, setShowManageTicketsModal, setShowRegisterTagsModal, setShowSendTicketCopyModal, setUpdateButtonNumberOfTickets, getSortByList, getShowExportAllTicketModal, setShowExportAllTicketModal, getShowTicketNotification, setSortByList, setOrderByList } from "../../../../store/internal"
import { EventParentElementInterface, ListTicketsControllerInterface } from "./indexModel";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getProfileId } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getOwner_id } from "../../../../store/callcenter";
import ListTickets from ".";
import { Ticket } from "../../../../domain/entities/ticket";
import Utils from "../../../../core/shared/utils";
import UserService from "../../../../services/user-service";
import { saveAs } from 'file-saver';
import { DataTicketsExport } from "../../../../services/requestsInterfacesModel";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { Button, Popover } from "react-bootstrap";
import { TicketContext } from "../../../../core/context/ticket-context";
import { TicketNotifyContext } from "../../../../core/context/ticket-notification-context";
import constsApi from "../../../../services/constsApi";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { ListName, OrderBy, SortBy } from "../../../../core/enums/order-sort-by";
import constsRouters from "../../../../routes/constsRouter";

const ListOfTicketsController: FC<ListTicketsControllerInterface> = (props) => {
    const [currentTicketId, setCurrentTicketId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [deleteListTickets, setDeleteListTickets] = useState([]);
    const [tickets, setTickets] = useState(null);
    const [currentStatusPage, setCurrentStatusPage] = useState("");
    const [showCustomFieldModalEdit, setShowCustomFieldModalEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [whoIsViewingList, setWhoIsViewingList] = useState([])
    const [ticketModal, setTicketModal] = useState(null);
    const [goToViewTicket, setGoToViewTicket] = useState<boolean>(false)

    const dispatch = useDispatch();
    const ticket = useContext(TicketContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ticketNotification = useSelector(getShowTicketNotification)
    const AppRequesterConst = new AppRequesterController();     
    const { path } = useParams()
    const [allCheckboxesIndex, setAllCheckboxesIndex] = useState([]);

    const employee_id = new BroadcastChannel('employee_id')

    const values = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowExportAllTicketModal: useSelector(getShowExportAllTicketModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        },
        showSendTicketCopyModal: useSelector(getShowSendTicketCopyModal),
        getSortByList: useSelector(getSortByList)
    };
    /**
     * Tipos de usuário
     */
    const isAdmin = constsApi.profile[0].id === values.user.profile_id;
    const isAgent = constsApi.profile[1].id === values.user.profile_id;
    const isOwner = constsApi.profile[2].id === values.user.profile_id;
    const isMaster = constsApi.profile[3].id === values.user.profile_id;

    useEffect(() => {
        employee_id.onmessage = (e) => {
            if (e.data != "" && e.data === values.employee.id) {
                setGoToViewTicket(true)
            } else {
                setGoToViewTicket(false)
            }
        }
    }, [employee_id])


    const [sortList, setSortList] = useState('');

    useEffect(() => {
        if (values.getSortByList) {
            setSortList(values.getSortByList.sort_by)
        }
    }, [values.getSortByList]);

    /* const ticketNo = useContext(TicketNotifyContext)
    const idNo = ticketNo.getId() */

    /* useEffect(() => {
        if (ticketNotification) {
            props.setCurrentTicketFunction({ id: idNo });
            dispatch(setShowEditTicketsModal(true));
            ticketNo.setID('')
            ticketNo.setClick(false)
            dispatch(setShowTicketNotification(false))
        }

    }, [ticketNotification]) */

    useEffect(() => {

        if (values.showSendTicketCopyModal) {
            dispatch(setShowSendTicketCopyModal(false));
        }
        if (values.internal.getShowExportAllTicketModal) {
            dispatch(setShowExportAllTicketModal(false));
        }
    }, []);

    useEffect(() => {
        if (props?.datas !== undefined) {
            if (props.statusPage !== currentStatusPage) {
                setCurrentStatusPage(props.statusPage);     
            }
            if (props?.datas && !tickets) {
                setTickets(props?.datas);
            } else if (tickets) {
                setTickets(props?.datas);
                if (deleteListTickets.length > 0) {
                    Utils.changeStyleElementById('buttonManageTickets', 'display', 'block');
                    Utils.changeStyleElementById('buttonExportAllTickets', 'display', 'none');
                }
            }
        } else {
            setTickets(undefined);
        }
    }, [props.datas as Ticket[]]);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTickets");
    }, [tickets]);

    // verificando o location filterOptionTicketsCx do componente ButtonOrderByList para obter o sortby a ser utilizado
    useEffect(() => {
        // dispatch(setOrderByList(OrderBy.desc));
        // setOrderByList(OrderBy.desc);

        let sortByFromButtonComponent = localStorage.getItem('filterOptionTicketsCx');
        sortByFromButtonComponent = sortByFromButtonComponent || 'home_tickets.list_tickets.headers.order_by_create_date_title';
        sortByFromButtonComponent = sortByFromButtonComponent === 'home_tickets.list_tickets.headers.order_by_create_date_title'?
            SortBy.updated_level2_date: SortBy.consumer_last_message;

        dispatch(setSortByList({ sort_by: sortByFromButtonComponent, listName: ListName.ListOfTickets }));
    }, []);

    const handleShowCustomFieldModalEdit = () => setShowCustomFieldModalEdit(true);
    const handleCloseCustomFieldModalEdit = () => setShowCustomFieldModalEdit(false);

    const showExportAllTicketModal = () => {
        dispatch(setShowExportAllTicketModal(true));
    } 

    useEffect(() => {
        const checkbox = document.getElementById("Allcheckboxes") as HTMLInputElement;
        if (checkbox) {
            checkbox.checked = false;
        }
    }, [tickets?.length]);

    const callBackModalCustomField = () => {
        setCurrentPage(1)
        setAux([])
        const controller = new AbortController()

    }

    const deleteTickets = async (listTickets: any[], isDeletingSingleTicket?: boolean) => {
        const headers = UserService.getHeaders()
        const info = {
            headers,
            // headers: {
            //     "Content-Type": "application/json; charset=utf-8",
            //     Authorization: "Bearer " + values.token.value
            // },
            data: {
                ids: listTickets
            }
        };

        await AppRequesterConst.Delete(
            '/ticket', info,
            (response: Object) => { },
            (data: Object) => {
                if (isDeletingSingleTicket) {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.success_deleted_ticket", { reference_id: ticket.getTicketReferenceId() }), signalIcon: true, visibility: true }))
                } else {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.action_confirmed"), signalIcon: true, visibility: true }));
                }

                setTimeout(() => {
                    dispatch(setShowAlertFeedback({ visibility: false }));
                }, 4000);

                props.updateListAfterDelete();
            },
            (error) => {
                if (error.response.status === 500) {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.ticket_not_deleted"), visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                }

                setTimeout(() => {
                    dispatch(setShowAlertFeedback({ visibility: false }));
                }, 4000);
            },
            navigate,
            dispatch,
            setIsLoading,
            () => {
                dispatch(setUpdateButtonNumberOfTickets(true));
            },
        );
    }

    const deleteTicketById = async (listTickets: any[]) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Delete(
            '/ticket/' + listTickets[0], { headers: headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.success_deleted_ticket", { reference_id: ticket.getTicketReferenceId() }), signalIcon: true, visibility: true }))

                props.updateListAfterDelete();
            },
            (error) => {
                if (error.response.status === 500) {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.ticket_not_deleted"), visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                }

                setTimeout(() => {
                    dispatch(setShowAlertFeedback({ visibility: false }));
                }, 4000);
            },
            navigate,
            dispatch,
            setIsLoading,
            () => {
                dispatch(setUpdateButtonNumberOfTickets(true));
            },
        );
    }

    const exportTickets = async (listTickets: any[], hasAdditionalContent: boolean) => {
        const headers = UserService.getHeaders()
        const request = {
            tickets: listTickets,
            hasAdditionalContent: hasAdditionalContent
        };

        await AppRequesterConst.Post(
            '/ticket/export', request, { headers },
            (response: Object) => { },
            (data: DataTicketsExport) => {
                if (data.status === 200 && data.data) {
                    if (hasAdditionalContent) {
                        const arrayBuffer = new Uint8Array(data.data.file.content.data).buffer;
                        const blob = new Blob([arrayBuffer], {
                            type: 'application/zip'
                        });
                        saveAs(blob, data.data.file.filename)
                        dispatch(setShowAlertFeedback({ message: 'Tickets exportados com sucesso', visibility: true, signalIcon: true }));
                    } else {
                        var decoder = new TextDecoder();
                        var uintData = Uint8Array.from(data.data.file.content.data);
                        var arrayBufferData = uintData.buffer;
                        var decoded = decoder.decode(arrayBufferData);
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = `data:${data.data.file.mimetype};charset=utf-8,` + encodeURI(decoded);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = data.data.file.filename;
                        hiddenElement.click();
                        dispatch(setShowAlertFeedback({ message: 'Tickets exportados com sucesso', visibility: true, signalIcon: true }));
                    }
                }
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
            () => {
                // dispatch(setUpdateButtonNumberOfTickets(true));
            },
        );
    }

    const reopenTicket = async (id: string) => {
        const headers = UserService.getHeaders()

        let JsonSend = {
        }

        await AppRequesterConst.Put(
            `ticket/reopen-ticket/${id}`, JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
                if(data.status === 200){
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.success_reopen_ticket"), visibility: true, signalIcon: true }))
                    props.setEditTicket(true);
                    showEditModal3(id);
                    props.updateListAfterDelete();
                }
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const recoverTicket = async (id: string) => {
        const headers = UserService.getHeaders()

        const JsonSend = {
            "ticket_id": id
        }

        await AppRequesterConst.Put(
            'ticket/recovery', JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.success_recover_ticket"), visibility: true, signalIcon: true }))
                props.setEditTicket(true);
                props.updateListAfterDelete();
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const selectModalTicket = (event, data) => {
        setTicketModal(data);
        showSendTicketCopyModal(event);
    }

    const functionConfirmation = () => {
        setDeleteListTickets((state) => {
            deleteTickets(state, true);
            return state;
        });

        if (document.getElementById("buttonManageTickets")) {
            document.getElementById("buttonManageTickets").style.display = "none";
        }
        setDeleteListTickets([])
    }

    const deleteSelectedsConfirmation = () => {
        setDeleteListTickets((state) => {
            if(state.length > 20){
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_error_deleted"), signalIcon: false, visibility: true }));
            } else {
                deleteTickets(state);

                for (let i = 0; i < props.datas.length; i++) {
                    const checkbox = document.getElementById("checkboxes" + i) as HTMLInputElement;

                    if (checkbox?.checked === true) {
                        checkbox.checked = false;
                    }
                }

                if (document.getElementById("buttonManageTickets")) {
                    document.getElementById("buttonManageTickets").style.display = "none";
                    document.getElementById('buttonExportAllTickets').style.display = 'block';
                }
                setDeleteListTickets([])
                return state;
            }
        });

    }

    const deleteAllSelected = () => {
        let whoIsViewing = []

        if (deleteListTickets.length >= 1) {
            whoIsViewingList.map((itemWhoIsViewingFlag) => {
                if (itemWhoIsViewingFlag != "null") {
                    whoIsViewing.push(itemWhoIsViewingFlag)
                }
            })

            if (whoIsViewing.length >= 1) {
                dispatch(setShowConfirmationModal({
                    visibility: true, text: {
                        "title": t("home_tickets.list_tickets.agents_in_service_title"), "body": (<span>{t("home_tickets.list_tickets.agents_in_service_body_a")}<EyeIcon/>{t("home_tickets.list_tickets.agents_in_service_body_b")}</span>), "buttonConfirmationText": "Ok", functionConfirmation: () => {
                            ticket.setTicketId(null)
                            ticket.setTicketReferenceId(null)
                            ticket.setTicketWhoIsViewing(null)
                        }
                    }
                }))
            } else {
                dispatch(setShowConfirmationModal({
                    visibility: true, text: { "title": t("home_tickets.list_tickets.delete_selecteds"), "body": t("home_tickets.list_tickets.alert_feedback.deleted_selecteds"), 'id': '', "buttonReturnText": t("home_tickets.list_tickets.cancel_delete_selecteds"), "buttonConfirmationText": t("home_tickets.list_tickets.delete") },
                    functionConfirmation: () => deleteSelectedsConfirmation()
                }))
            }
        }
    }

    const exportAllSelectedsConfirmation = (hasAdditionalContent: boolean) => {
        setDeleteListTickets((state) => {
            exportTickets(state, hasAdditionalContent);
            for (let i = 0; i < props.datas.length; i++) {
                const checkbox = document?.getElementById("checkboxes" + i) as HTMLInputElement;
                if (checkbox?.checked === true) {
                    checkbox.checked = false;
                }
            }
            const allCheckboxes = document?.getElementById("Allcheckboxes") as HTMLInputElement;
            if (allCheckboxes?.checked === true) {
                allCheckboxes.checked = false;
            }
            Utils.changeStyleElementById('buttonManageTickets', 'display', 'none');
            setDeleteListTickets([])
            return state;
        })
    }

    const exportCsvAllSelected = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": t('home_tickets.list_tickets.export_to_csv.title'), "body": t('home_tickets.list_tickets.export_to_csv.body'), 'id': '', "buttonConfirmationText": t('home_tickets.list_tickets.export_to_csv.button_confirmation'), "twoButton": t('home_tickets.list_tickets.export_to_csv.two_button'), "isExport": true },
            functionConfirmation: () => exportAllSelectedsConfirmation(true),
            functionConfirmation2: () => exportAllSelectedsConfirmation(false)
        }));
    }

    const AllCheckboxesClick = (e: { currentTarget: { checked: boolean } }) => {
        let aux = [];
        let auxWhoIsViewing = []
        let auxReferenceId = []
        if (e.currentTarget?.checked === true) {
            for (let i = 0; i < tickets.length; i++) {
                const checkbox = document.getElementById("checkboxes" + i) as HTMLInputElement;
                if (checkbox && !checkbox.disabled) {
                    if(allCheckboxesIndex.length < 100) {
                            !allCheckboxesIndex.includes(i) && allCheckboxesIndex.push(i)
                            checkbox.checked = true;
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_error_assigned"), signalIcon: false, visibility: true }));
                        const Allcheckboxes = document.getElementById("Allcheckboxes") as HTMLInputElement;
                        if (Allcheckboxes) {
                            Allcheckboxes.checked = false;
                        }
                        break;
                    }
                    aux.push(checkbox.parentElement.parentElement.parentElement.parentElement.id.split("*who_is_viewing*")[0]);
                    auxWhoIsViewing.push(checkbox.parentElement.parentElement.parentElement.parentElement.id.split("*who_is_viewing*")[1].split("*ticket_reference_id*")[0])
                    auxReferenceId.push(checkbox.parentElement.parentElement.parentElement.parentElement.id.split("*ticket_reference_id*")[1])
                }
            }
            setDeleteListTickets(aux);
            setWhoIsViewingList(auxWhoIsViewing);
            Utils.changeStyleElementById('buttonManageTickets', 'display', 'block');
            Utils.changeStyleElementById('buttonExportAllTickets', 'display', 'none');
        } else {
            for (let i = 0; i < tickets.length; i++) {
                const checkbox = document.getElementById("checkboxes" + i) as HTMLInputElement;
                if (checkbox && !checkbox.disabled) {
                    checkbox.checked = false;
                }
                setAllCheckboxesIndex([]);
            }
            setDeleteListTickets([]);
            Utils.changeStyleElementById('buttonManageTickets', 'display', 'none');
            Utils.changeStyleElementById('buttonExportAllTickets', 'display', 'block');
        }
    }

    const CheckboxesClick = (e: any, index: number) => {
        let count = 0;
        let aux = [];
        let auxWhoIsViewing = [];
        let auxReferenceId = [];
        !allCheckboxesIndex.includes(index) ?
            allCheckboxesIndex.push(index)
            :
            allCheckboxesIndex.splice(allCheckboxesIndex.indexOf(index), 1);
        for (let i = 0; i < tickets.length; i++) {
            if ((document?.getElementById("checkboxes" + i) as HTMLInputElement)?.checked === true) {
                count++;
                aux.push((document.getElementById("checkboxes" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*who_is_viewing*")[0]);
                auxWhoIsViewing.push((document.getElementById("checkboxes" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*who_is_viewing*")[1].split("*ticket_reference_id*")[0])
                auxReferenceId.push((document.getElementById("checkboxes" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ticket_reference_id*")[1])
                setDeleteListTickets(aux);
                setWhoIsViewingList(auxWhoIsViewing);
            }
        }
        if (count !== 0) {
            Utils.changeStyleElementById('buttonManageTickets', 'display', 'block');
            Utils.changeStyleElementById('buttonExportAllTickets', 'display', 'none');
        } else {
            setDeleteListTickets([]);
            Utils.changeStyleElementById('buttonManageTickets', 'display', 'none');
            Utils.changeStyleElementById('buttonExportAllTickets', 'display', 'block');
        }

        const checkbox = document.getElementById("Allcheckboxes") as HTMLInputElement;
        if (checkbox) {
            checkbox.checked = count === tickets.length;
        }
        if (allCheckboxesIndex.length === 100) {
            dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_limit_reached"), signalIcon: false, visibility: true }));
        }
    }

    const setCurrentTicketInfo = (e: { currentTarget: { id: string }; }) => {
        setCurrentTicketId(e.currentTarget.id.split("*ID*")[1].split("*who_is_viewing*")[0]);
        getInformationTicketFromEditOptions(e)
    }

    /**
     * Pega as informações do ticket quando clicado em editar na listagem
     * @param e 
     */
    const getInformationTicketFromEditOptions = (e: { currentTarget: { id: string } }) => {
        ticket.setTicketReferenceId(e.currentTarget.id.split("*ID*")[0])
        ticket.setTicketId(e.currentTarget.id.split("*ID*")[1].split("*who_is_viewing*")[0])
        ticket.setTicketWhoIsViewing(e.currentTarget.id.split("*who_is_viewing*")[1])
    }

    /**
     * Pega as informações do ticket quando clicado na listagem 
     * @param e 
     */
    const getInformationFromTicket = (e: EventParentElementInterface) => {
        ticket.setTicketReferenceId(e.currentTarget?.parentElement?.parentElement?.id.split("*ticket_reference_id*")[1])
        ticket.setTicketId(e.currentTarget?.parentElement?.parentElement?.id.split("*who_is_viewing*")[0])
        ticket.setTicketWhoIsViewing(e.currentTarget?.parentElement?.parentElement?.id?.split("*who_is_viewing*")[1].split("*ticket_reference_id*")[0])
    }

    /**
     * Função para click no ticket da listagem
     * 
     * Verifica se já existe um agente atuando no ticket, caso exista o ticket é bloqueado para edição
     * até que o agente saia do ticket. Caso não exista o ticket ficará desbloqueado. Caso seja: Proprietário,
     * Master ou Administrador, o ticket deverá ficar sempre debloqueado, independente de existir agente no ticke
     * ou não.
     */
    const checkIfThereIsAnAgent = () => {
        const isWhoIsViewing = ticket.getTicketWhoIsViewing() !== 'null'
        const pathName = window.location.pathname;

        if (isAdmin || isOwner || isMaster) {
            dispatch(setShowEditTicketsModal(true));
            dispatch(setBlockLoadTicketBackup(true));
        } else {
            if (isAgent && isWhoIsViewing && pathName == '/tickets') {
                dispatch(setShowConfirmationModal({
                    visibility: true,
                    text: { "title": t("home_tickets.list_tickets.action_popover.agent_in_service"), "body": t("home_tickets.list_tickets.action_popover.the_ticket"), "id": `#${ticket.getTicketReferenceId()}`, "middleText": t("home_tickets.list_tickets.action_popover.he_is_being_accompanied_by_the_agent"), "warning": `${ticket.getTicketWhoIsViewing()}`, "end": t("home_tickets.list_tickets.action_popover.and_it_cannot_be_edited_at_the_moment"), "buttonConfirmationText": t("home_tickets.view_ticket.confirmation.blocked_ticket.confirm") },
                    functionConfirmation: () => { }
                }))
            } else {
                dispatch(setShowEditTicketsModal(true));
                dispatch(setBlockLoadTicketBackup(true));
            }
        }
    }

    /**
     * Verifica se existem tickets abertos pelo agente em alguma aba e 
     * pergunta se deseja prosseguir
     * @param e 
     * @param processing
     */
    const openViewTicketConfirm = async (e?: EventParentElementInterface, processing?: boolean) => {
        getInformationFromTicket(e)

        if(processing) {
            dispatch(setShowConfirmationModal({ visibility: true, text: {"title": t("home_tickets.view_ticket.processing.title"), "body": t("home_tickets.view_ticket.processing.body"), 'id': '', "buttonConfirmationText": t("home_tickets.view_ticket.processing.confirmation")}, functionConfirmation: () => {}}))
        }
        else if (props.fromHistory) {
            openViewTicketConfirmed(e);
        } else {
            // requisição para saber se existe ticket aberto
            const headers = UserService.getHeaders();

            await AppRequesterConst.Get(
                `/ticket/has-ticket-openned/${values.employee.id}`, { headers },
                (response: Object) => { },
                (data) => {
                    if (data?.status === 200 && data?.data?.tickets?.length > 0) {
                        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.action_popover.ticked_openned_title"), "body": t("home_tickets.list_tickets.action_popover.ticked_openned_body"), 'id': '', "buttonReturnText": t("home_tickets.list_tickets.action_popover.no"), "buttonConfirmationText": t("home_tickets.list_tickets.action_popover.yes") }, functionConfirmation: () => disconnectOldTabs(e) }))
                    } else {
                        openViewTicketConfirmed(e);
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );
            if (goToViewTicket) {
                dispatch(setShowConfirmationModal({ visibility: true, text: { "title": "Fechar tickets em outras abas", "body": "Parece que existem outros tickets abertos deseja fecha-los e abrir este ticket?", 'id': '', "buttonReturnText": "Não", "buttonConfirmationText": "Sim" }, functionConfirmation: () => disconnectOldTabs(e) }))
            } else {
                openViewTicketConfirmed(e);
            }
        }
    }

    // desconecta os tickets anteriores e abre o ticket atual
    const disconnectOldTabs = async (e, outer?) => {
        const headers = UserService.getHeaders();
        const JsonSend = {
            should_disconnect_old_tabs: true,
            ticket_id: ""
        };

        let element;
        if (e.currentTarget) {
            element = e?.currentTarget;
        } else {
            element = e?.target;
        }

        // procurando parentNode que seja o elemento com o id do ticket
        while (element) {
            // let classListElement = Array.from(element.classList);
            if (element && element?.className === "list-group-item" /* && classListElement.includes('list-group-item') */) {
                props.setCurrentTicketFunction({ id: element?.id });
                break;
            }

            element = element?.parentElement;
        }
        JsonSend["ticket_id"] = outer ? outer : element?.id;

        props.setCurrentTicketFunction({ id: ticket.getTicketId() })

        JsonSend["ticket_id"] = ticket.getTicketId();

        await AppRequesterConst.Post(
            `/ticket/disconnect-old-tabs/${values.employee.id}`, JsonSend, { headers },
            (response: Object) => { },
            (data) => {
                if (data?.status === 200) { }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, () => { openViewTicketConfirmed(e); /* ensureDisconnectOldTabs(e) */ } // depois da requisição de fechar os tickets abre o ticket atual
        );
    }

    const openViewTicketConfirmed = async (e/* : EventParentElementInterface */) => {
        let element;
        if (e.currentTarget) {
            element = e.currentTarget;
        } else {
            element = e.target;
        }

        // procurando parentNode que seja o elemento com o id do ticket
        while (element) {
            // let classListElement = Array.from(element.classList);
            if (element && element.className === "list-group-item" /* && classListElement.includes('list-group-item') */) {
                props.setCurrentTicketFunction({ id: element.id });
                break;
            }

            element = element.parentElement;
        }
        // props.updateListfunction();
        checkIfThereIsAnAgent()
    }

    const EyeIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="3%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="2" />
                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
            </svg>
        )
    }

    const deleteTicket = () => {
        let arrayTicket = []
        if (ticket.getTicketWhoIsViewing() != "null") {
            dispatch(setShowConfirmationModal({
                visibility: true, text: {
                    "title": "Agente em atendimento", "body": `O ticket `, "id": `#${ticket.getTicketReferenceId()}`, "middleText": " está sendo acompanhado pelo agente", "warning": `${ticket.getTicketWhoIsViewing()}`, "end": " e não pode ser excluído no momento.", "buttonConfirmationText": "Ok", functionConfirmation: () => {
                        ticket.setTicketId(null)
                        ticket.setTicketReferenceId(null)
                        ticket.setTicketWhoIsViewing(null)
                    }
                }
            }))
        } else {
            arrayTicket.push(ticket.getTicketId())
            dispatch(setShowConfirmationModal({
                visibility: true, text: { "title": t("home_tickets.list_tickets.delete_ticket"), "body": t("home_tickets.list_tickets.delete_ticket"), "id": ' #' + ticket.getTicketReferenceId() + '?', "buttonReturnText": t("home_tickets.list_tickets.cancel_delete_selecteds"), "buttonConfirmationText": t("home_tickets.list_tickets.delete") },
                functionConfirmation: () => deleteTicketById(arrayTicket)
            }));

            let aux = [];
            aux.push(currentTicketId.split("*ID*")[1]);
            // setDeleteListTickets(aux);

        }

    }

    const disconetTicket = async (e, id: string) => {
        const headers = UserService.getHeaders();
        await AppRequesterConst.Get(
            `/ticket/has-ticket-openned/${values.employee.id}`, { headers },
            (response: Object) => { },
            (data) => {
                if (data?.status === 200 && data?.data?.tickets?.length > 0) {
                    if ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) {
                        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.action_popover.ticked_openned_title"), "body": t("home_tickets.list_tickets.action_popover.ticked_openned_body"), 'id': '', "closeButton": false, "buttonConfirmationText": t("home_tickets.list_tickets.action_popover.yes") }, functionConfirmation: () => disconnectOldTabs(e, id) }))
                    }
                    else {
                        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.action_popover.ticked_openned_title"), "body": t("home_tickets.list_tickets.action_popover.ticked_openned_body"), 'id': '', "buttonReturnText": t("home_tickets.list_tickets.action_popover.no"), "buttonConfirmationText": t("home_tickets.list_tickets.action_popover.yes") }, functionConfirmation: () => disconnectOldTabs(e, id) }))
                    }
                } else {
                    props.setCurrentTicketFunction({ id: id });
                    dispatch(setShowEditTicketsModal(true));
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const showEditModal2 = async (e: EventParentElementInterface) => {
        disconetTicket(e, ticket.getTicketId())
        const isWhoIsViewing = ticket.getTicketWhoIsViewing() != "null"

        if (goToViewTicket) {
            dispatch(setShowConfirmationModal({ visibility: true, text: { "title": "Fechar tickets em outras abas", "body": "Parece que existem outros tickets abertos deseja fecha-los e abrir este ticket?", 'id': '', "buttonReturnText": "Não", "buttonConfirmationText": "Sim" }, functionConfirmation: () => disconnectOldTabs(e) }))
        } else {
            if (isAdmin || isOwner || isMaster) {
                props.setCurrentTicketFunction({ id: e.currentTarget.parentElement.parentElement.id });
                dispatch(setShowEditTicketsModal(true));
            } else {
                if (isAgent && isWhoIsViewing) {
                    dispatch(setShowConfirmationModal({
                        visibility: true,
                        text: { "title": t("home_tickets.list_tickets.action_popover.agent_in_service"), "body": t("home_tickets.list_tickets.action_popover.the_ticket"), "id": `#${ticket.getTicketReferenceId()}`, "middleText": t("home_tickets.list_tickets.action_popover.he_is_being_accompanied_by_the_agent"), "warning": `${ticket.getTicketWhoIsViewing()}`, "end": t("home_tickets.list_tickets.action_popover.and_it_cannot_be_edited_at_the_moment"), "buttonConfirmationText": t("home_tickets.view_ticket.confirmation.blocked_ticket.confirm") },
                        functionConfirmation: () => { }
                    }))
                } else {
                    props.setCurrentTicketFunction({ id: e.currentTarget.parentElement.parentElement.id });
                    dispatch(setShowEditTicketsModal(true));
                }
            }
        }
    }

    const showEditModal3 = (id: string) => {
        navigate(constsRouters?.routers?.tickets?.path)
        ticket.setTicketId(id)
        // props.setCurrentTicketFunction(id)
        dispatch(setShowEditTicketsModal(true))
    }

    const formatedDate = (date: Date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    const showSendTicketCopyModal = (e: EventParentElementInterface) => {
        setShowSendTicketCopyModal(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        dispatch(setShowSendTicketCopyModal(true));
    }

    const showManageTicketsModal = () => {
        dispatch(setShowManageTicketsModal(true));
    }

    const resolveAllSelected = () => {
        let whoIsViewing = []

        if (deleteListTickets.length >= 1) {
            whoIsViewingList.map((itemWhoIsViewingFlag) => {
                if(itemWhoIsViewingFlag != "null") {
                    whoIsViewing.push(itemWhoIsViewingFlag)
                }
            })

            if (whoIsViewing.length >= 1) {
                dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.agents_in_service_title"), "body": (<span>{t("home_tickets.list_tickets.agents_in_service_body_a")}<EyeIcon/>{t("home_tickets.list_tickets.agents_in_service_body_b")}</span>), "buttonConfirmationText": "Ok", functionConfirmation: () => {
                    ticket.setTicketId(null)
                    ticket.setTicketReferenceId(null)
                    ticket.setTicketWhoIsViewing(null)
                }}}))
            } else {
                dispatch(setShowConfirmationModal({
                    visibility: true, text: { "title": t("home_tickets.list_tickets.set_tickets_as_resolved"), "body": t("home_tickets.list_tickets.set_tickets_as_resolved_body"), 'id': '', "buttonReturnText": t("home_tickets.list_tickets.cancel"), "buttonConfirmationText": t("home_tickets.list_tickets.set_as_resolveds")},
                    functionConfirmation: () => setResolvedTicketsConfirmation()
                }))
            }
        }
    }

    const setResolvedTicketsConfirmation = () => {
        setDeleteListTickets((state) => {
            if(state.length > 100){
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_error"), signalIcon: false, visibility: true }));
            } else {
                setResolvedTickets(state)
                for (let i = 0; i < props.datas.length; i++) {
                    const checkbox = document.getElementById("checkboxes" + i) as HTMLInputElement;
    
                    if (checkbox?.checked === true) {
                        checkbox.checked = false;
                    }
                }
    
                if (document.getElementById("buttonManageTickets")) {
                    document.getElementById("buttonManageTickets").style.display = "none";
                    document.getElementById("buttonExportAllTickets").style.display = "block";
                }
                setDeleteListTickets([])
            }
            return state;
        });
    }

    const addAllTags = () => {
        let whoIsViewing = []

        if (deleteListTickets.length >= 1) {
            whoIsViewingList.map((itemWhoIsViewingFlag) => {
                if(itemWhoIsViewingFlag != "null") {
                    whoIsViewing.push(itemWhoIsViewingFlag)
                }
            })

            if (whoIsViewing.length >= 1) {
                dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.agents_in_service_title"), "body": (<span>{t("home_tickets.list_tickets.agents_in_service_body_a")}<EyeIcon/>{t("home_tickets.list_tickets.agents_in_service_body_b")}</span>), "buttonConfirmationText": "Ok", functionConfirmation: () => {
                    ticket.setTicketId(null)
                    ticket.setTicketReferenceId(null)
                    ticket.setTicketWhoIsViewing(null)
                }}}))
            } else {
                setAddAllTagsConfirmation();
            }
        }
    }

    const setAddAllTagsConfirmation = () => {
        setDeleteListTickets((state) => {
            if(state.length > 100){
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_error"), signalIcon: false, visibility: true }));
            } else {
                dispatch(setShowRegisterTagsModal(true));
            }
            return state;
        });
    }

    const setResolvedTickets = async (ids: any[]) => {
        const headers = UserService.getHeaders()

        let JsonSend = {
            ticketIds: ids
        }

        await AppRequesterConst.Put(
            `ticket/solve-batch-tickets`, JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.action_confirmed"), visibility: true, signalIcon: true }))
                props.updateListAfterDelete()
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.tickets_resolved_error"), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const removeAllAssigned = () => {
        let whoIsViewing = []

        if (deleteListTickets.length >= 1) {
            whoIsViewingList.map((itemWhoIsViewingFlag) => {
                if(itemWhoIsViewingFlag != "null") {
                    whoIsViewing.push(itemWhoIsViewingFlag)
                }
            })

            if (whoIsViewing.length >= 1) {
                dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.list_tickets.agents_in_service_title"), "body": (<span>{t("home_tickets.list_tickets.agents_in_service_body_a")}<EyeIcon/>{t("home_tickets.list_tickets.agents_in_service_body_b")}</span>), "buttonConfirmationText": "Ok", functionConfirmation: () => {
                    ticket.setTicketId(null)
                    ticket.setTicketReferenceId(null)
                    ticket.setTicketWhoIsViewing(null)
                }}}))
            } else {
                dispatch(setShowConfirmationModal({
                    visibility: true, text: { "title": t("home_tickets.list_tickets.remove_assigned_agents"), "body": t("home_tickets.list_tickets.remove_assigned_agents_body"), 'id': '', "buttonReturnText": t("home_tickets.list_tickets.cancel"), "buttonConfirmationText": t("home_tickets.list_tickets.remove_agents")},
                    functionConfirmation: () => setRemoveAllAssignedConfirmation()
                }))
            }
        }
    }

    const setRemoveAllAssignedConfirmation = () => {
        setDeleteListTickets((state) => {
            if(state.length > 100){
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.max_tickets_error_assigned"), signalIcon: false, visibility: true }));
            } else {
                setRemoveAllAssigned(state)
                for (let i = 0; i < props.datas.length; i++) {
                    const checkbox = document.getElementById("checkboxes" + i) as HTMLInputElement;
    
                    if (checkbox?.checked === true) {
                        checkbox.checked = false;
                    }
                }
    
                if (document.getElementById("buttonManageTickets")) {
                    document.getElementById("buttonManageTickets").style.display = "none";
                    document.getElementById("buttonExportAllTickets").style.display = "block";
                }
                setDeleteListTickets([])
            }
            return state;
        });
    }

    const setRemoveAllAssigned = async (ids: any[]) => {
        const headers = UserService.getHeaders()

        let JsonSend = {
            ticket_ids: ids
        }

        await AppRequesterConst.Put(
            `ticket/remove-assignment`, JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.action_confirmed"), visibility: true, signalIcon: true }))
                setTickets([])
                props.updateListAfterDelete()
            },
            (error: { response: { data: { code_cxpress: number }}}) => {
                if(error.response.data.code_cxpress === 748) { /* erro ao tentar retirar agentes atribuidos de tickets que não possuem agentes */
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.error_not_assigned_agent"), visibility: true, signalIcon: false }))
                }
                else {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.tickets_resolved_error"), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading
        )
    }

    const popoverHistory = (data) => (
        <Popover id={currentTicketId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                {data.channel_id === "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a" && (
                    <>
                        {data.status_id === TicketStatus.Abandoned || data.status_id === TicketStatus.Resolved ? (
                            <Button onClick={() => reopenTicket(data?.id)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_tickets.list_tickets.action_popover_history.reopen_ticket")}</Button>
                        ) : (
                            <Button onClick={() => recoverTicket(data?.id)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_tickets.list_tickets.action_popover_history.recover_ticket")}</Button>
                        )}
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    </>
                )}
                <Button onClick={(e) => selectModalTicket(e, data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t("home_tickets.list_tickets.action_popover.send_ticket_copy")} </Button>
            </Popover.Body >
        </Popover >
    );

    return <ListTickets
        currentTicketId={currentTicketId}
        t={t}
        showEditModal2={showEditModal2}
        deleteTicket={deleteTicket}
        selectModalTicket={selectModalTicket}
        handleShowCustomFieldModalEdit={handleShowCustomFieldModalEdit}
        showCustomFieldModalEdit={showCustomFieldModalEdit}
        handleCloseCustomFieldModalEdit={handleCloseCustomFieldModalEdit}
        callBackModalCustomField={callBackModalCustomField}
        updateListfunction={props.updateListfunction}
        isLoading={isLoading}
        values={values}
        ticketModal={ticketModal}
        tickets={tickets}
        checkboxes={props.checkboxes}
        AllCheckboxesClick={AllCheckboxesClick}
        callbackOrderByTickets={props.callbackOrderByTickets}
        requesterUserActiveColumn={props.requesterUserActiveColumn}
        assignedEmployeeActiveColumn={props.assignedEmployeeActiveColumn}
        actionsActiveColumn={props.actionsActiveColumn}
        dataText={props.dataText}
        ticketStateLevelType={props.ticketStateLevelType}
        CheckboxesClick={CheckboxesClick}
        openViewTicketConfirm={openViewTicketConfirm}
        formatedDate={formatedDate}
        setCurrentTicketInfo={setCurrentTicketInfo}
        showCreateTicket={props.showCreateTicket}
        deleteAllSelected={deleteAllSelected}
        exportCsvAllSelected={exportCsvAllSelected}
        popoverHistory={popoverHistory}
        showManageTicketsModal={showManageTicketsModal}
        fromHistory={props.fromHistory}
        resolveAllSelected={resolveAllSelected}
        height={props.height}
        removeAllAssigned={removeAllAssigned}
        deleteListTickets={deleteListTickets}
        setDeleteListTickets={setDeleteListTickets}
        updateListAfterDelete={props.updateListAfterDelete}
        addAllTags={addAllTags}
        sortList={sortList}
        showExportAllTicketModal={showExportAllTicketModal}
        allCheckboxesIndex={allCheckboxesIndex}
    />
}

export default ListOfTicketsController;